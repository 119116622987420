$theme-colors: (
  "primary": #592b82,
  "danger": #e06c6c,
  "warning": #e0d86c,
);

$white-color: #ffffff;
$disabled-background-color: #ccc;
$text-card-color: #444;
$valido-color: #2fcc44;
$neutro-color:#939393;
$inexistente-color:#ff0000;
$inconsistente-color:#fb0;

#root{
  background-color: #e4e4f4;
  background-image: url('./assets/img/bg-pessoal.png');
  background-size: cover;
  background-blend-mode: overlay;
  background-repeat: no-repeat;
  background-position-y: 60px;
}

h1 {
  margin: 32px 0px 0px 32px;
}

.cardTotalWrap {
  min-width: 100px;
  display: inline-block;
  background: $white-color;
  border: 1px solid $disabled-background-color;
  box-shadow: 0 4px 6px 0 rgba(31, 70, 88, 0.04);
  padding: 16px;
  margin: 8px;
  text-align: center;
  border-radius: 6px;
  position: relative;
  overflow: hidden;
  cursor: pointer;

  &:hover {
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.12), 0 2px 4px rgba(0, 0, 0, 0.06);
  }

  &::after {
    content: '';
    height: 8px;
    width: 70%;
    border-radius: 4px;
    top: -3px;
    left: 50%;
    position: absolute;
    transform: translateX(-50%);
  }

  .total {
    font-weight: 700;
    font-size: 40px;
    line-height: 48px;
    letter-spacing: -1.29px;
  }
  .titulo {
    color: $text-card-color;
    font-size: 16px;
    font-weight: 700;
    line-height: 16px;
    letter-spacing: -0.54px;
  }

  &.miniature {
    padding: 4px;
    margin: 4px;

    &::after {
      content: '';
      height: 6px;
    }

    .total {
      font-weight: 700;
      font-size: 20px;
      line-height: 22px;
    }

    .titulo {
      font-size: 12px;
      font-weight: 700;
      line-height: 16px;
    }
  }
}

.valido{
  &::after {
    background: $valido-color;
  }

  .total{
    color: $valido-color;
  }
}

.neutro{
  &::after {
    background: $neutro-color;
  }

  .total{
    color: $neutro-color;
  }
}

.inconsistente{
  &::after {
    background: $inconsistente-color;
  }

  .total{
    color: $inconsistente-color;
  }
}

.inexistente{

  &::after {
    background: $inexistente-color;
  }

  .total{
    color: $inexistente-color;
  }
}

.accordion .card {
  .card-header{
    padding: 0px;
  }

  .card-body{
    padding: 0px 1.25rem 0px 1.25rem;
  }
}
@import '~bootstrap/scss/bootstrap.scss';
