.alert{
    display: flex;
    padding: 7px;
    z-index: 1;
    flex-direction: row;
    align-content: space-between;
    position: absolute;
    right: 30px;
    top: 25px;
    width: 600px;
    color:white;
    border-radius: 5px;
    font-size: large;
}

.hide{
    display: hide;
}
.icon{
    margin: auto 5px;
}

.fechar{
    margin: auto 5px;
}
.content{
    width: 100%;
    margin-left: 20px;
    margin-top: 3px;
}

.error{
    background-color: red;
}

.success{
    background-color: green;
}

.warning{
    background-color: sandybrown;
}