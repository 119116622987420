.logo{
    width: 150px;
    height: 150px;
    margin: 25px auto;
    background-color: #ddd;
    border-radius: 5px;
}

.div-input{
    width: 100%;
}

.login-card{
    width: 400px;
    z-index: 1;
    margin-top: calc(100vh/2 - 280px);
    margin-left: calc(100vw/2 - 250px);
    padding: 30px;
    height: 450px;
    border-radius: 10px;
    border: 1px solid #ddd;
    box-shadow: #999 1px 1px 10px;
}

.cadastro-link{
    display: inline-block;
    color: #999;
    text-decoration: none;
    font-size: 1.2em;
    margin: auto;
    text-align: center;
}

.cadastro-link:hover{
    color: #333;
    font-size: 1.22em;
}

.button-login{
    width: 100%;
    margin-bottom: 15px;
    font-weight: bold;
}

.div-link-cadastro{
    width: 100%;
    text-align: center;
}